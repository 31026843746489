import React, { useState, useEffect, useCallback } from "react";
import Icons from "../../miscellaneous/icons/Icons";
import alcanzalogo from "../../photos&logos/letterallwhitebig.webp";
import Login from "../login/login/Login";
import SignUp from "../login/signup/SignUp";
import rm from "./Menu.module.scss";
/********************************************
 * NavigationMenu Mobile
 ********************************************/
export default function NavigationMenuMobile() {
  return (
    <div className={rm.login_mobile}>
      <div className={rm.mobile_wrapper00}>
        <div className={rm.mobileimg_wrapper}>
          <SignUp />
        </div>
      </div>
      <div className={rm.mobile_wrapper01}>
        <div className={rm.mobile_login_path}>
          <img src={alcanzalogo} alt="logo" height={50} width={50} />
        </div>
      </div>
      <div className={rm.mobile_box00}>
        <h4>
          Accede o regístrate rápidamente en la plataforma con tu cuenta de
          Google
        </h4>
        <Login />
        <div className={rm.box01}></div>
      </div>
      <div className={rm.mobile_wrapper02}></div>
    </div>
  );
}
