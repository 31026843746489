// database paths tables
export enum FN {
  courses = "courses",
  testimonials = "testimonials",
  methodology = "methodology",
  users = "users",
  materials = "materials",
  userdashboard = "userdashboard",
  homework = "homework",
}

export enum MethodNum {
  methId = 1,
  objec = 2,
}

export enum falTruChcks {
  truely = "true",
  falsy = "false",
}
