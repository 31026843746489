import React, { useCallback, useEffect, useState } from "react";
import { auth, googleSignIn } from "../../../firebase/firebase";
import { useAppDispatch } from "../../../redux/hooks";
import { notify } from "../../react-toatify/ReactToastify";
import { paths } from "../../../interface";
import { FN } from "../../../miscellaneous/enums/enums";
import { uniqueId } from "../../../miscellaneous/uniqueId/uniqueId";
import { onCurrentUserInfo, onUserLogin } from "../../../redux/userSlice";
import { useNavigate } from "react-router";
import FirebaseModule from "../../../firebase/FirebaseModule";
import Icons from "../../../miscellaneous/icons/Icons";
import na from "../../menu/Menu.module.scss";

/***************************
 * Login
 * exported to NavigationMenu.tsx
 ***************************/
export default function Login() {
  const firebaseModule = FirebaseModule();
  const [runsOnce, setRunsOnce] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getData = useCallback(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) return;
      const ref = firebaseModule.checkDataInFirebase(FN.users, user.uid);

      ref
        .then((snapShoot) => {
          if (snapShoot?.exists()) {
            dispatch(onCurrentUserInfo([snapShoot.data()]));
            dispatch(onUserLogin(true));
            navigate(paths.userdashboard);
          } else if (!snapShoot?.exists()) {
            dispatch(onUserLogin(false));
            dispatch(onCurrentUserInfo([]));
            notify("No esta registrado", "error", {
              toastId: uniqueId(),
              autoClose: 1300,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notify("Problemas con la base de datos [conexion, login]", "error", {
            toastId: uniqueId(),
            autoClose: 1300,
          });
        });
    });
  }, [dispatch, firebaseModule, navigate]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && runsOnce) {
      getData();
      setRunsOnce(false);
    }

    return () => {
      isMounted = false;
    };
  }, [getData, runsOnce]);

  function onHandlerSignIn() {
    googleSignIn();
    setRunsOnce(true);
  }

  return (
    <div className={na.navigation_buttons} onClick={() => onHandlerSignIn()}>
      <Icons iconName="googleIcon" /> <span>Iniciar sesión </span>
    </div>
  );
}
