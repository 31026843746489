import React, { Suspense, lazy, useEffect, useCallback, useState } from "react";
import NavigationMenuMobile from "../menu/NavigationMenuMobile";
//  lazy
const Introduction = lazy(() => import("./introduction/Introduction"));
const Information = lazy(() => import("./information/Information"));
const Courses = lazy(() => import("./courses/Courses"));
const Methodology = lazy(() => import("./methodology/Methodology"));
const Footer = lazy(() => import("./footer/Footer"));
const Testimonials = lazy(() => import("./testimonials/Testimonials"));
/**************************
 * Main
 **************************/
export default function Main() {
  const [isInstalled, setIsInstalled] = useState<boolean>(false);
  const [runsOnce, setRunsOnce] = useState<boolean>(true);

  const handlerCheckInstalation = useCallback((): boolean => {
    const apiBoolean = window.matchMedia("(display-mode: standalone)").matches; // returns a boolean
    return apiBoolean;
  }, []);

  
  useEffect(() => {
    let isMounted = true;

    if (isMounted && runsOnce) {
      setIsInstalled(handlerCheckInstalation());
      setRunsOnce(false);
    }

    return () => {
      isMounted = false;
    };
  }, [handlerCheckInstalation, runsOnce]);

  const fallMessage = (
    <div style={{ width: "100%", textAlign: "center", marginTop: "1em" }}>
      Loading.....
    </div>
  );
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
      }}
    >
      {isInstalled ? (
        <NavigationMenuMobile />
      ) : (
        <Suspense fallback={fallMessage}>
          <Introduction />
          <Information />
          <Courses />
          <Methodology />
          <Testimonials />
          <Footer />
        </Suspense>
      )}
    </div>
  );
}
