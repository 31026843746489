import React from "react";
import { getIcons } from "./icon";
import ic from "./Icons.module.scss";

interface IconsName {
  iconName: string;
  clicked?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
type IconType = ReturnType<typeof getIcons>;

/************************
 * Icons
 * getIcons must have something to return, otherwise typescript will complain
 * downgrade to for render issues to the newest version npm install react-icons@5.4.0
 **********************/
export default function Icons({ iconName, clicked }: IconsName) {
  const icon: IconType = getIcons(iconName);
  return (
    <button className={ic.buttonicon} onClick={clicked}>
      {icon}
    </button>
  );
}