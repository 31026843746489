import React, { useState, useEffect, useCallback } from "react";
import FirebaseModule from "../../../firebase/FirebaseModule";
import { notify } from "../../react-toatify/ReactToastify";
import { uniqueId } from "../../../miscellaneous/uniqueId/uniqueId";
import { auth, googleSignIn } from "../../../firebase/firebase";
import { useNavigate } from "react-router";
import { FN } from "../../../miscellaneous/enums/enums";
import { useAppDispatch } from "../../../redux/hooks";
import { onUserLogin, onCurrentUserInfo } from "../../../redux/userSlice";
import { paths } from "../../../interface";
import na from "../../menu/Menu.module.scss";
import Icons from "../../../miscellaneous/icons/Icons";
/******************************
 * SignUp
 * exported to NavigationMenu.tsx
 *****************************/
export default function SignUp() {
  const firebaseModule = FirebaseModule();
  const [runsOnce, setRunsOnce] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const callBack = useCallback(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) return;
      const ref = firebaseModule.checkDataInFirebase(FN.users, user.uid);
      ref
        .then((snapShot) => {
          if (!snapShot?.exists()) {
            let d = {
              id: user.uid,
              name: user.displayName,
              email: user.email,
              photo: user.photoURL,
              activateduser: "false",
              student: "false",
              aditionalstudent1: "",
              aditionalstudent2: "",
              aditionalstudent3: "",
              aditionalstudent4: "",
              aditionalstudent5: "",
              code: "",
              level: "",
              bookclass: "",
              bookpractice: "",
              rewards1: "",
              rewards2: "",
              rewards3: "",
              rewards4: "",
              rewards5: "",
              rewards6: "",
              rewards7: "",
              rewards8: "",
              rewards9: "",
              rewards10: "",
              homework1: "",
              homework2: "",
              homework3: "",
              homework4: "",
              homework5: "",
              homework6: "",
              homework7: "",
              homework8: "",
              homework9: "",
              project1: "",
              project2: "",
              project3: "",
              writtentest1: "",
              writtentest2: "",
              writtentest3: "",
              writtentest4: "",
              writtentest5: "",
              oraltest1: "",
              oraltest2: "",
              oraltest3: "",
              oraltest4: "",
              oraltest5: "",
              camera: "",
              participation: "",
            };
            const ref1 = firebaseModule.addDataInFirebase(
              FN.users,
              user.uid,
              d,
            );
            ref1.then(() => {
              dispatch(onUserLogin(true));
              dispatch(onCurrentUserInfo([d]));
              navigate(paths.userdashboard);
            });
          } else if (snapShot.exists()) {
            dispatch(onUserLogin(false));
            dispatch(onCurrentUserInfo([]));
            notify("La cuenta ya esta registrada", "warning", {
              toastId: uniqueId(),
              autoClose: 1300,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notify(
            "No pudo comunicarse con la base de datos [signup]",
            "warning",
            {
              toastId: uniqueId(),
              autoClose: 1200,
            },
          );
        });
    });
  }, [firebaseModule, navigate, dispatch]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && runsOnce) {
      callBack();
      setRunsOnce(false);
    }

    return () => {
      isMounted = false;
    };
  }, [runsOnce, callBack]);

  // Google signIn
  function onHandlerSignUp() {
    googleSignIn();
    setRunsOnce(true);
  }

  return (
    <div className={na.navigation_buttons} onClick={() => onHandlerSignUp()}>
      <Icons iconName="googleIcon" /> <span>registrarse </span>
    </div>
  );
}
